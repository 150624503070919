import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainPage from "./MainPage/MainPage";
import HelpPage from "./HelpPage/HelpPage";
import LoginPage from "./LoginPage/LoginPage";
import NewUserPage from "./NewUserPage/NewUserPage";
import UpdateUserPage from "./UpdateUserPage/UpdateUserPage";
import BookingPage from "./BookingPage/BookingPage";
import VerifyEmailPage from "./VerifyEmailPage/VerifyEmailPage";
import UserBookingHistoryPage from "./UserBookingHistoryPage/UserBookingHistoryPage";
import AdminBookingStatisticsPage from "./AdminBookingStatisticsPage/AdminBookingStatisticsPage";
import AdminConfigurationPage from "./AdminConfigurationPage/AdminConfigurationPage";
import AdminUsersPage from "./AdminUsersPage/AdminUsersPage";
import Logout from "./Logout";
import { getUser } from "../api/userApi";
import { getAllConfigurations } from "../api/configurationApi";
import "../css/main.css";
import "../css/index.css";

// TODO
// Sorting in Buttons module
// Consecutive slots for open booking
// Delete activity type needs explicit delete command to REST
// TRIM och user name & email at cration and login

function App() {
  const [reloadUserData, setReloadUserData] = useState(false);
  const [instance, setInstance] = useState(1);
  const [configData, setConfigData] = useState([]); // configData holds data for all instances
  const [userData, setUserData] = useState({
    status: "NOT_LOGGED_IN",
    creationTime: "",
    email: "",
    emailVerified: false,
    firstName: "",
    lastActive: "",
    lastName: "",
    nameList: [],
    phoneNumber: "",
    user: "",
    userid: null,
    admin: false,
  });

  // Effect to load user data
  useEffect(() => {
    document.title = "Ullstämma ridbanebokning";
    let _userData = {
      status: "NOT_LOGGED_IN",
      creationTime: "",
      email: "",
      emailVerified: false,
      firstName: "",
      lastActive: "",
      lastName: "",
      nameList: [],
      phoneNumber: "",
      user: "",
      userid: null,
      admin: false,
    };
    const savedUserId = localStorage.getItem("userId"); // userId is keept in browser local storage to preserve login between sessions.
    if (savedUserId !== null) {
      getUser(savedUserId).then((fetchedUserData) => {
        let status = "NOT_LOGGED_IN";
        if (fetchedUserData.hasOwnProperty("Item")) {
          if (fetchedUserData.Item.emailVerified) {
            status = "LOGGED_IN";
          } else {
            status = "EMAIL_NOT_VERIFIED";
          }
          _userData = { ...fetchedUserData.Item, status: status };
        }
        setUserData(_userData);
      });
    }
  }, [reloadUserData]);

  // Effect to get the page configuration
  useEffect(() => {
    getAllConfigurations().then((_configData) => {
      if (_configData.hasOwnProperty("Items")) {
        setConfigData(_configData.Items);
      }
    });
  }, []);
  return (
    <Router>
      <ToastContainer autoClose={3000} position="top-center" hideProgressBar />

      <Routes>
        <Route
          path="/"
          element={
            <MainPage
              userData={userData}
              configData={configData}
              instance={instance}
            />
          }
        />
        <Route
          path="/login"
          element={
            <LoginPage
              configData={configData}
              instance={instance}
              setUserData={setUserData}
              reloadUserData={reloadUserData}
              setReloadUserData={setReloadUserData}
            />
          }
        />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/newUser"
          element={
            <NewUserPage
              userData={userData}
              configData={configData}
              instance={instance}
            />
          }
        />
        <Route
          path="/updateUser"
          element={
            <UpdateUserPage
              userData={userData}
              setUserData={setUserData}
              configData={configData}
              instance={instance}
            />
          }
        />
        <Route
          path="/verifyemail"
          element={
            <VerifyEmailPage userData={userData} configData={configData} />
          }
        />
        <Route
          path="/userHistory"
          element={
            <UserBookingHistoryPage
              userData={userData}
              configData={configData}
              instance={instance}
            />
          }
        />
        <Route
          path="/book/:instance"
          element={
            <BookingPage
              userData={userData}
              configData={configData}
              setUserData={setUserData}
              instance={instance}
              setInstance={setInstance}
            />
          }
        />
        <Route
          path="/help"
          element={
            <HelpPage
              userData={userData}
              configData={configData}
              instance={instance}
            />
          }
        />
        <Route
          path="/help/:helpPage"
          element={
            <HelpPage
              userData={userData}
              configData={configData}
              instance={instance}
            />
          }
        />
        <Route path="/admin" element={<NoMatch />} />
        <Route
          path="/admin/bookingStatistics"
          element={
            <AdminBookingStatisticsPage
              userData={userData}
              configData={configData}
              instance={instance}
            />
          }
        />
        <Route
          path="/admin/configuration"
          element={
            <AdminConfigurationPage
              userData={userData}
              configData={configData}
            />
          }
        />
        <Route
          path="/admin/users"
          element={
            <AdminUsersPage
              userData={userData}
              configData={configData}
              instance={instance}
            />
          }
        />
      </Routes>
    </Router>
  );
}

function NoMatch() {
  return (
    <div>
      <h3>No match</h3>
    </div>
  );
}

export default App;
