import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function RegisterForm(props) {
  let navigate = useNavigate();

  return (
    <Container fluid="md">
      <br />
      <Form onSubmit={props.onSubmit}>
        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Användarnamn</Form.Label>
          <Form.Control
            type="input"
            placeholder="Användarnamn"
            name="user"
            value={props.newUser.user}
            onChange={props.onChange}
          />
          {props.errors.user && (
            <Form.Text className="alert alert-danger">
              {props.errors.user}
            </Form.Text>
          )}
          <Form.Text className="text-muted">
            Ange ett valfritt användarnamn som du vill använda när du loggar in
            till bokningssystemet.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Epostadress</Form.Label>
          <Form.Control
            type="email"
            placeholder="Epostadress"
            name="email"
            value={props.newUser.email}
            onChange={props.onChange}
          />
          {props.errors.email && (
            <Form.Text className="alert alert-danger">
              {props.errors.email}
            </Form.Text>
          )}
          <Form.Text className="text-muted">
            Vi delar aldrig din epostadress med någon annan.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Förnamn</Form.Label>
          <Form.Control
            type="input"
            placeholder="Förnamn"
            name="firstName"
            value={props.newUser.firstName}
            onChange={props.onChange}
          />
          {props.errors.firstName && (
            <Form.Text className="alert alert-danger">
              {props.errors.firstName}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Efternamn</Form.Label>
          <Form.Control
            type="input"
            placeholder="Efternamn"
            name="lastName"
            value={props.newUser.lastName}
            onChange={props.onChange}
          />
          {props.errors.lastName && (
            <Form.Text className="alert alert-danger">
              {props.errors.lastName}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicUser">
          <Form.Label>Telefonnummer</Form.Label>
          <Form.Control
            type="input"
            placeholder="Telefonnummer"
            name="phoneNumber"
            value={props.newUser.phoneNumber}
            onChange={props.onChange}
          />
          {props.errors.phoneNumber && (
            <Form.Text className="alert alert-danger">
              {props.errors.phoneNumber}
            </Form.Text>
          )}
        </Form.Group>

        <div className="button-container">
          <Button variant="dark" type="submit" name="register" value="save">
            Spara
          </Button>
          <Button
            variant="secondary"
            name="abort"
            value="abort"
            onClick={() => navigate(-1)}
          >
            Avbryt
          </Button>
        </div>
        <br />
        {props.userid ? (
          <p>
            <strong>ICAL-länk:</strong>&nbsp;
            https://uj9bq815f8.execute-api.eu-north-1.amazonaws.com/ical/
            {props.userid}
          </p>
        ) : null}
      </Form>
    </Container>
  );
}
RegisterForm.propTypes = {
  newUser: PropTypes.object.isRequired,
  userid: PropTypes.number,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default RegisterForm;
